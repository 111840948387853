import * as React from "react";
import { Typography, Container } from "@mui/material";

const SimplePage = (props) => (
  <Container>
    <Typography variant="h2">{props.title}</Typography>
    <Typography variant="body1">{props.body}</Typography>
  </Container>
)

export default SimplePage;
