import * as React from "react";

import Layout from "../sections/Layout";
import Seo from "../sections/SEO";
import SimplePage from "../components/SimplePage"

const NotFoundPage = () => (
  <Layout title="Monitor">
    <SimplePage title={"Not Found"} body={"Sorry, that page doesn't seem to exist."} />
  </Layout>
);

export default NotFoundPage;

export const Head = () => (
  <Seo />
)